import localeDe from 'antd/lib/locale-provider/de_DE';
import localeEn from 'antd/lib/locale-provider/en_US';
import localeEs from 'antd/lib/locale-provider/es_ES';
import { useLanguage } from 'hooks/translations/useLanguage';

const useTimeZone = () => {
  const { lang } = useLanguage();
  const lenguaje = lang;
  const timeZone = {
    es: 'America/Mexico_City',
    en: 'America/New_York',
    de: 'Europe/Berlin',
  }[lenguaje];

  const antDesignLocale = {
    es: localeEs,
    en: localeEn,
    de: localeDe,
  }[lenguaje];

  return { timeZone, antDesignLocale };
};

export default useTimeZone;
