import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CREATE,
  DELETE,
  READ,
  READ_SINGLE,
  UPDATE,
  VEHICLE,
} from '../actions/actionTypes';

const initialState = {
  vehicle: {},
  vehicles: [],
  loading: false,
};

function vehicle(state = initialState, action) {
  switch (action.type) {
    case `${VEHICLE} ${CREATE} ${API_PENDING}`:
    case `${VEHICLE} ${DELETE} ${API_PENDING}`:
    case `${VEHICLE} ${READ_SINGLE} ${API_PENDING}`:
    case `${VEHICLE} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${VEHICLE} ${ADD}`:
      return {
        ...state,
        vehicle: {},
        loading: false,
      };
    case `${VEHICLE} ${CREATE} ${API_SUCCESS}`:
    case `${VEHICLE} ${DELETE} ${API_SUCCESS}`:
    case `${VEHICLE} ${CREATE} ${API_FAILURE}`:
    case `${VEHICLE} ${DELETE} ${API_FAILURE}`:
    case `${VEHICLE} ${READ_SINGLE} ${API_FAILURE}`:
      return {
        ...state,
        loading: false,
      };
    case `${VEHICLE} ${UPDATE} ${API_SUCCESS}`:
    case `${VEHICLE} ${UPDATE} ${API_FAILURE}`:
    case `${VEHICLE} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${VEHICLE} ${READ} ${API_SUCCESS}`:
      return { ...state, vehicles: action.payload, loading: false };
    case `${VEHICLE} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        vehicle: action.payload,
      };
    default:
      return { ...state };
  }
}

export default vehicle;
