import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CREATE,
  DELETE,
  INSURANCE,
  READ,
  READ_SINGLE,
} from '../actions/actionTypes';

const initialState = {
  insuranceForm: {
    company_name: '',
    type: '',
    address: {
      house: '',
      street: '',
      postalCode: '',
      district: '',
      city: '',
      state: '',
      stateCode: '',
      country: '',
      countryCode: '',
    },
  },
  insurances: [],
  insurance: {},
  loading: false,
};

function insurance(state = initialState, action) {
  switch (action.type) {
    case `${INSURANCE} ${CREATE} ${API_PENDING}`:
    case `${INSURANCE} ${READ_SINGLE} ${API_PENDING}`:
    case `${INSURANCE} ${READ} ${API_PENDING}`:
    case `${INSURANCE} ${DELETE} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${INSURANCE} ${CREATE} ${API_SUCCESS}`:
    case `${INSURANCE} ${CREATE} ${API_FAILURE}`:
    case `${INSURANCE} ${READ_SINGLE} ${API_FAILURE}`:
    case `${INSURANCE} ${READ} ${API_FAILURE}`:
    case `${INSURANCE} ${DELETE} ${API_SUCCESS}`:
    case `${INSURANCE} ${DELETE} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${INSURANCE} ${READ} ${API_SUCCESS}`:
      return { ...state, insurances: action.payload, loading: false };
    case `${INSURANCE} ${READ_SINGLE} ${API_SUCCESS}`:
      return { ...state, insurance: action.payload, loading: false };
    default:
      return { ...state };
  }
}

export default insurance;
