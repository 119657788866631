import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CARE_TAKER,
  COUNTRY,
  CREATE,
  DELETE,
  NATIONALITY,
  READ,
  READ_SINGLE,
  UPDATE,
} from 'actions/actionTypes';

const initialState = {
  nationalities: [],
  countries: [],
  caretaker: {
    address: {},
  },
  caretakers: [],
  loading: false,
};

function caretaker(state = initialState, action) {
  switch (action.type) {
    case `${CARE_TAKER} ${CREATE} ${API_PENDING}`:
    case `${CARE_TAKER} ${DELETE} ${API_PENDING}`:
    case `${CARE_TAKER} ${READ_SINGLE} ${API_PENDING}`:
    case `${CARE_TAKER} ${READ} ${API_PENDING}`:
    case `${NATIONALITY} ${READ} ${API_PENDING}`:
    case `${COUNTRY} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${CARE_TAKER} ${CREATE} ${API_SUCCESS}`:
    case `${CARE_TAKER} ${DELETE} ${API_SUCCESS}`:
    case `${CARE_TAKER} ${CREATE} ${API_FAILURE}`:
    case `${CARE_TAKER} ${DELETE} ${API_FAILURE}`:
    case `${CARE_TAKER} ${READ_SINGLE} ${API_FAILURE}`:
    case `${CARE_TAKER} ${UPDATE} ${API_SUCCESS}`:
    case `${CARE_TAKER} ${UPDATE} ${API_FAILURE}`:
    case `${CARE_TAKER} ${READ} ${API_FAILURE}`:
    case `${NATIONALITY} ${READ} ${API_FAILURE}`:
    case `${COUNTRY} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${CARE_TAKER} ${ADD}`:
      return {
        ...state,
        caretaker: {
          address: {},
        },
        loading: false,
      };
    case `${CARE_TAKER} ${READ} ${API_SUCCESS}`:
      return { ...state, caretakers: action.payload, loading: false };
    case `${CARE_TAKER} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        caretaker: action.payload,
      };
    case `${NATIONALITY} ${READ} ${API_SUCCESS}`:
      return { ...state, nationalities: action.payload, loading: false };
    case `${COUNTRY} ${READ} ${API_SUCCESS}`:
      return { ...state, countries: action.payload, loading: false };
    default:
      return { ...state };
  }
}

export default caretaker;
