import { notification } from 'antd';
import { API_FAILURE, API_SUCCESS } from '../actions/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notificationsMiddleware = () => (next: any) => (action: any) => {
  next(action);

  if (action.error_response) {
    const message = action?.error_response?.data?.message;
    if (typeof message === 'object') {
      for (const err in message) {
        notification.error({ message: message[err] });
      }
      return;
    }
    if (process.env.NODE_ENV === 'development') {
      notification.error({ message, duration: 3 });
    }
  }

  if (action.meta && action.meta.notifications) {
    const { successMessage, failureMessage } = action.meta.notifications;
    if (action.type.includes(API_SUCCESS) && successMessage) {
      if (typeof successMessage === 'object') {
        notification.success({ ...successMessage });
        return;
      }
      notification.success({ message: successMessage, duration: 3 });
    }
    if (action.type.includes(API_FAILURE) && failureMessage) {
      if (typeof successMessage === 'object') {
        notification.error({ ...failureMessage });
        return;
      }
      notification.error({ message: failureMessage, duration: 3 });
    }
  }
};

export default notificationsMiddleware;
