import { combineReducers } from 'redux';

import account from './account';
import api from './api';
import app from './app';
import caregiver from './caregiver';
import caretaker from './caretaker';
import deleteData from './delete';
import doctor from './doctor';
import schedules from './duty_schedule';
import event from './event';
import insurance from './insurance';
import insurancecompany from './insurance_company';
import invoices from './invoices';
import modal from './modal';
import patient from './patient';
import pharmacy from './pharmacy';
import route from './route';
import vehicle from './vehicle';

export const appReducers = {
  account,
  app,
  caretaker,
  vehicle,
  insurancecompany,
  insurance,
  pharmacy,
  patient,
  modal,
  api,
  event,
  caregiver,
  doctor,
  deleteData,
  route,
  invoices,
  schedules,
};

export default combineReducers(appReducers);
