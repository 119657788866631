import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CLEAN_USER_SERVICE,
  DATE,
  READ,
  SET_COMPANY_DATA,
  SET_RIGHT_DRAWER_VISIBILITY,
  SET_USER_SERVICE,
  TIME,
  USER,
} from '../actions/actionTypes';

const initialState = {
  rightDrawerVisibility: false,
  user: {},
  company: {},
  subscription: {
    plan_id: 1,
  },
  plans: [],
  currentDate: '',
  time: null,
  loading: true,
  debtorMessage: false,
};

function app(state = initialState, action) {
  switch (action.type) {
    case SET_RIGHT_DRAWER_VISIBILITY:
      return { ...state, rightDrawerVisibility: action.payload };
    case SET_USER_SERVICE:
      return { ...state, user: { service: action.payload } };
    case SET_COMPANY_DATA:
      return { ...state, company: action.payload };
    case CLEAN_USER_SERVICE:
      return { ...state, user: {} };
    case `${USER} ${READ} ${API_PENDING}`:
    case `${DATE} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${USER} ${READ} ${API_SUCCESS}`:
      return { ...state, loading: false, ...action.payload };
    case `${DATE} ${READ} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        currentDate: action.payload.date,
      };
    case `${USER} ${READ} ${API_FAILURE}`:
    case `${DATE} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${TIME} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${TIME} ${API_SUCCESS}`:
      return { ...state, loading: false, time: action.payload.current_time };
    default:
      return { ...state };
  }
}

export default app;
