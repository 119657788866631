import {
  API_SUCCESS,
  CAREGIVER,
  CARE_TAKER,
  DELETE,
  DOCTOR,
  INSURANCE_COMPANY,
  PATIENT,
  PHARMACY,
  READ,
  VEHICLE,
} from '../actions/actionTypes';

const initialState = {
  dataSource: [],
  loading: false,
};

function deleteData(state = initialState, action) {
  switch (action.type) {
    case `${PATIENT} ${READ} ${API_SUCCESS}`:
    case `${DOCTOR} ${READ} ${API_SUCCESS}`:
    case `${CARE_TAKER} ${READ} ${API_SUCCESS}`:
    case `${CAREGIVER} ${READ} ${API_SUCCESS}`:
    case `${INSURANCE_COMPANY} ${READ} ${API_SUCCESS}`:
    case `${VEHICLE} ${READ} ${API_SUCCESS}`:
    case `${PHARMACY} ${READ} ${API_SUCCESS}`:
      return { ...state, dataSource: action.payload, loading: false };

    case `${PATIENT} ${DELETE} ${API_SUCCESS}`:
    case `${DOCTOR} ${DELETE} ${API_SUCCESS}`:
    case `${CARE_TAKER} ${DELETE} ${API_SUCCESS}`:
    case `${CAREGIVER} ${DELETE} ${API_SUCCESS}`:
    case `${INSURANCE_COMPANY} ${DELETE} ${API_SUCCESS}`:
    case `${VEHICLE} ${DELETE} ${API_SUCCESS}`:
    case `${PHARMACY} ${DELETE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
      };

    default:
      return { ...state };
  }
}

export default deleteData;
