import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  DELETE,
  DUTY_SCHEDULE,
  READ,
} from 'actions/actionTypes';

interface Schedule {
  id: number;
  date: string;
  doctor: string;
}

interface State {
  schedules: Schedule[];
  loading: boolean;
}

const initialState: State = {
  schedules: [],
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function doctor(state: State = initialState, action: any) {
  switch (action.type) {
    case `${DUTY_SCHEDULE} ${ADD} ${API_PENDING}`:
    case `${DUTY_SCHEDULE} ${DELETE} ${API_PENDING}`:
    case `${DUTY_SCHEDULE} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${DUTY_SCHEDULE} ${DELETE} ${API_SUCCESS}`:
    case `${DUTY_SCHEDULE} ${ADD} ${API_FAILURE}`:
    case `${DUTY_SCHEDULE} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${DUTY_SCHEDULE} ${READ} ${API_SUCCESS}`:
      return { ...state, schedules: action.payload, loading: false };
    case `${DUTY_SCHEDULE} ${ADD} ${API_SUCCESS}`:
      return {
        ...state,
        schedules: [...state.schedules, action.payload],
        loading: false,
      };
    default:
      return { ...state };
  }
}

export default doctor;
