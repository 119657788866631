import { withRouter } from 'next/router';

import { Menu } from 'antd';

import { imagesList } from 'consts/images';

import styles from 'assets/main.module.less';

const SideBar = () => {
  return <Menu items={imagesList} className={styles.menu} mode="vertical" />;
};

export default withRouter(SideBar);
