import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  COUNTRY,
  CREATE,
  DELETE,
  INSURANCE_COMPANY,
  NATIONALITY,
  READ,
  READ_SINGLE,
  UPDATE,
} from 'actions/actionTypes';

const initialState = {
  nationalities: [],
  countries: [],
  insurancecompany: {
    address: {},
    bankAccount: {},
  },
  insurancecompanies: [],
  loading: false,
};

function insurancecompany(state = initialState, action) {
  switch (action.type) {
    case `${INSURANCE_COMPANY} ${CREATE} ${API_PENDING}`:
    case `${INSURANCE_COMPANY} ${DELETE} ${API_PENDING}`:
    case `${INSURANCE_COMPANY} ${READ_SINGLE} ${API_PENDING}`:
    case `${INSURANCE_COMPANY} ${READ} ${API_PENDING}`:
    case `${NATIONALITY} ${READ} ${API_PENDING}`:
    case `${COUNTRY} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${INSURANCE_COMPANY} ${ADD}`:
      return {
        ...state,
        insurancecompany: {
          address: {},
          bankAccount: {},
        },
        loading: false,
      };
    case `${INSURANCE_COMPANY} ${CREATE} ${API_SUCCESS}`:
    case `${INSURANCE_COMPANY} ${DELETE} ${API_SUCCESS}`:
    case `${INSURANCE_COMPANY} ${CREATE} ${API_FAILURE}`:
    case `${INSURANCE_COMPANY} ${DELETE} ${API_FAILURE}`:
    case `${INSURANCE_COMPANY} ${READ_SINGLE} ${API_FAILURE}`:
    case `${NATIONALITY} ${READ_SINGLE} ${API_FAILURE}`:
    case `${COUNTRY} ${READ_SINGLE} ${API_FAILURE}`:
      return {
        ...state,
        loading: false,
      };
    case `${INSURANCE_COMPANY} ${UPDATE} ${API_SUCCESS}`:
    case `${INSURANCE_COMPANY} ${UPDATE} ${API_FAILURE}`:
    case `${INSURANCE_COMPANY} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${INSURANCE_COMPANY} ${READ} ${API_SUCCESS}`:
      return { ...state, insurancecompanies: action.payload, loading: false };
    case `${NATIONALITY} ${READ} ${API_SUCCESS}`:
      return { ...state, nationalities: action.payload, loading: false };
    case `${COUNTRY} ${READ} ${API_SUCCESS}`:
      return { ...state, countries: action.payload, loading: false };
    case `${INSURANCE_COMPANY} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        insurancecompany: action.payload,
      };
    default:
      return { ...state };
  }
}

export default insurancecompany;
