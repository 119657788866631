import { createTheme } from '@mui/material';

const colors = {
  primary: '#083B66',
  basic_background: '#F2F2F2',
  yellow: {
    unselected: '#f5de70',
    selected: '#f5d65a',
    text: '#ffffff',
  },
  orange: {
    unselected: '#e78200',
    selected: '#d76f00',
    text: '#ffffff',
  },
  red: {
    unselected: '#ec4c4c',
    selected: '#d32f2f',
    text: '#ffffff',
  },
  green: {
    unselected: '#00d066',
    selected: '#00a74a',
    text: '#ffffff',
  },
  blue: {
    unselected: '#083b66',
    selected: '#072f57',
    text: '#ffffff',
  },
  gray: {
    unselected: '#808080',
    selected: '#4d4d4d',
    text: '#ffffff',
  },
  purple: {
    unselected: '#8e6cc3',
    selected: '#6f4dbd',
    text: '#ffffff',
  },
  seaBlue: {
    unselected: '#00a7c7',
    selected: '#0087a3',
    text: '#ffffff',
  },
  olive_green: {
    unselected: '#A8CFA2',
    selected: '#D4E7C5',
    text: '#ffffff',
  },
};

export const theme = createTheme({
  palette: {
    error: {
      main: '#ff4d4f',
      contrastText: '#fff',
    },
    success: {
      main: '#00de73',
      contrastText: '#fff',
    },
    primary: {
      main: '#083b66',
      contrastText: '#fff',
    },
    orange: {
      main: '#E78200',
      contrastText: '#fff',
    },
    gray: {
      main: '#f2f2f2',
      contrastText: '#083b66',
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
    yellow: {
      main: '#F5DE70',
      contrastText: '#fff',
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

export default colors;
