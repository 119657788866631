import axios from 'axios';

import {
  API_FAILURE,
  API_PENDING,
  API_REQUEST,
  API_SUCCESS,
  CLEAN_USER_SERVICE,
} from 'actions/actionTypes';

const apiSuccess = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any
) => {
  const { feature } = action.meta;

  const newAction = {
    type: `${feature} ${API_SUCCESS}`,
    payload: response,
    meta: action.meta,
  };
  if (response.data) {
    // TODO: data.error
    newAction.payload = response.data;
    return newAction;
  }
  newAction.type = `${feature} ${API_FAILURE}`;

  return newAction;
};

const apiError = (error, error_response, action) => {
  const { feature } = action.meta;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newAction: any = {
    type: `${feature} ${API_FAILURE}`,
    message: error,
    error_response,
    meta: action.meta,
  };

  if (error.message) {
    newAction.payload = error.message;
    return newAction;
  }

  return newAction;
};

const apiMiddleware =
  ({ dispatch }) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (next: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: any) => {
    next(action);
    if (action.type && action.type.includes(API_REQUEST)) {
      const { feature, api, callback, failureCallback } = action.meta;

      dispatch({ type: `${feature} ${API_PENDING}` });

      return axios({ ...api })
        .then((response) => {
          if (typeof callback === 'function') {
            callback(response);
          }
          dispatch(apiSuccess(response, action));
        })
        .catch((error) => {
          if (typeof failureCallback === 'function') {
            failureCallback(error);
          }
          dispatch(apiError(error, error.response, action));
          if (error?.response?.status === 401) {
            dispatch({ type: CLEAN_USER_SERVICE });
            window?.location.replace('/logout');
          }
        });
    }
  };

export default apiMiddleware;
