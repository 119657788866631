import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CARE_TAKER,
  CREATE,
  DELETE,
  EVENT,
  ORDER,
  READ,
  READ_SINGLE,
  SELECT,
  SERVICE,
  TASK,
  UPDATE,
} from '../actions/actionTypes';

const initialState = {
  caretakers: [],
  orders: [],
  services: [],
  events: [],
  event: {},
  selectedTasks: [],
  loading: false,
};

function transformevent(list) {
  return list && list.length
    ? list.map((event) => {
        return {
          id: event.id,
          patientId: event?.patient?.id,
          orders: event.orders,
          title: 'Title missing',
          allDay: false,
          start: new Date(event.startsAt),
          end: new Date(event.endsAt),
        };
      })
    : [];
}

function event(state = initialState, action) {
  switch (action.type) {
    case `${EVENT} ${CREATE} ${API_PENDING}`:
    case `${EVENT} ${UPDATE} ${API_PENDING}`:
    case `${EVENT} ${READ_SINGLE} ${API_PENDING}`:
    case `${SERVICE} ${READ_SINGLE} ${API_PENDING}`:
    case `${EVENT} ${READ} ${API_PENDING}`:
    case `${SERVICE} ${READ} ${API_PENDING}`:
    case `${EVENT} ${DELETE} ${API_PENDING}`:
    case `${SERVICE} ${DELETE} ${API_PENDING}`:
    case `${ORDER} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${TASK} ${SELECT}`:
      return { ...state, selectedTasks: action.payload };
    case `${EVENT} ${CREATE} ${API_SUCCESS}`:
    case `${EVENT} ${CREATE} ${API_FAILURE}`:
    case `${EVENT} ${READ_SINGLE} ${API_FAILURE}`:
    case `${SERVICE} ${READ_SINGLE} ${API_FAILURE}`:
    case `${EVENT} ${READ} ${API_FAILURE}`:
    case `${SERVICE} ${READ} ${API_FAILURE}`:
    case `${EVENT} ${DELETE} ${API_SUCCESS}`:
    case `${SERVICE} ${DELETE} ${API_SUCCESS}`:
    case `${EVENT} ${DELETE} ${API_FAILURE}`:
    case `${SERVICE} ${DELETE} ${API_FAILURE}`:
    case `${EVENT} ${READ} ${API_SUCCESS}`:
      return {
        ...state,
        events: transformevent(action.payload),
        loading: false,
      };
    case `${EVENT} ${UPDATE} ${API_SUCCESS}`:
      return { ...state, loading: false };
    case `${EVENT} ${READ_SINGLE} ${API_SUCCESS}`:
      return { ...state, event: action.payload, loading: false };
    case `${SERVICE} ${READ_SINGLE} ${API_SUCCESS}`:
      return { ...state, service: action.payload, loading: false };
    case `${SERVICE} ${READ} ${API_SUCCESS}`:
      return { ...state, services: action.payload, loading: false };
    case `${ORDER} ${READ} ${API_FAILURE}`:
    case `${EVENT} ${UPDATE} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${ORDER} ${READ} ${API_SUCCESS}`:
      return { ...state, orders: action.payload, loading: false };
    case `${CARE_TAKER} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${CARE_TAKER} ${READ} ${API_SUCCESS}`:
      return { ...state, caretakers: action.payload, loading: false };
    default:
      return { ...state };
  }
}

export default event;
