import { getHeaders, url } from '../api';

export const signup = (account) => ({
  url: `${url}/sign_up`,
  method: 'POST',
  data: { account },
  headers: {
    'Content-Type': 'application/json',
  },
});

export const login = (form) => ({
  method: 'POST',
  data: { ...form },
  url: `${url}/auth/employee/login`,
  headers: { 'Content-Type': 'application/json' },
});

export const sendRecoveryCodeToEmail = (email) => ({
  url: `${url}/employees/forgot-password`,
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
  data: { email },
});

export const changeForgottenPassword = ({ email, token, newPassword }) => ({
  url: `${url}/employees/change-password`,
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
  data: {
    email,
    resetPasswordToken: token,
    newPassword,
    currentPassword: '',
  },
});

export const passwordReset = (password, password_confirmation, email) => ({
  url: `${url}/passwords`,
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
  data: { password, password_confirmation, email },
});

export const requestAccounts = (params) => ({
  method: 'GET',
  url: `${url}/accounts`,
  headers: getHeaders(),
  params: {
    per: params.pagination.per,
    page: params.pagination.page,
    email: params.email,
  },
});

export const requestAccount = () => ({
  method: 'GET',
  url: `${url}/accounts/information`,
  headers: getHeaders(),
});
