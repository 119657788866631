import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  BILLING,
  DOWNLOAD,
  INVOICES,
  ORDER,
  READ,
} from 'actions/actionTypes';

const initialState = {
  invoices: [],
  billing: [],
  billingOrders: [],
  loading: false,
};

function invoices(state = initialState, action) {
  switch (action.type) {
    case `${INVOICES} ${READ} ${API_PENDING}`:
    case `${INVOICES} ${DOWNLOAD} ${API_PENDING}`:
    case `${BILLING} ${READ} ${API_PENDING}`:
    case `${BILLING} ${ORDER} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${INVOICES} ${READ} ${API_FAILURE}`:
    case `${INVOICES} ${DOWNLOAD} ${API_FAILURE}`:
    case `${BILLING} ${READ} ${API_FAILURE}`:
    case `${BILLING} ${ORDER} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${INVOICES} ${READ} ${API_SUCCESS}`:
      return { ...state, invoices: action.payload, loading: false };
    case `${BILLING} ${READ} ${API_SUCCESS}`:
      return { ...state, billing: action.payload, loading: false };
    case `${BILLING} ${ORDER} ${READ} ${API_SUCCESS}`:
      return { ...state, billingOrders: action.payload, loading: false };
    case `${INVOICES} ${DOWNLOAD} ${API_SUCCESS}`:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}

export default invoices;
