import { parseCookies, setCookie } from 'nookies';

/**
 *
 * @param ctx
 * @returns {string}
 */
export const getUserAccessToken = (ctx = {}) => {
  const cookies = parseCookies(ctx);
  return cookies.token;
};

export const setAuth = (userAccessToken) => {
  const options = { path: '/' };

  setCookie({}, 'token', userAccessToken, options);
};
