import { DateTime } from 'luxon';
import { useRoutes } from 'queries/useRoutes';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getOrdersFromRoutes } from 'utils/formats';

type IRoutesContext = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routesState: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRoutesState: (x: any[]) => void;

  unselectedOrdersIds: string[];
  setUnselectedOrdersIds: (x: string[]) => void;

  calendarStartDate?: Date;
  setCalendarStartDate: (x: Date) => void;

  calendarEndDate?: Date;
  setCalendarEndDate: (x: Date) => void;

  refetchAllRoutes: () => Promise<void>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caretakers: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCaretakers: (x: any[]) => void;

  caretakersSelected: { id: string; name: string }[];
  setCaretakersSelected: (x: { id: string; name: string }[]) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedulesState: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSchedulesState: (x: any[]) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSchedules: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedSchedules: Dispatch<SetStateAction<any[]>>;

  refetchAllSchedules: boolean;
  setRefetchAllSchedules: (x: boolean) => void;

  selectedRoutesIds: string[];
  setSelectedRoutesIds: (x: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: any[];
  routesLoading: boolean;
};

const contextDefaultValues: IRoutesContext = {
  routesState: [],
  setRoutesState: (x) => {},

  unselectedOrdersIds: [],
  setUnselectedOrdersIds: (x) => {},

  calendarStartDate: null,
  setCalendarStartDate: (x: Date) => {},

  calendarEndDate: null,
  setCalendarEndDate: (x: Date) => {},

  refetchAllRoutes: () => Promise.resolve(),

  caretakers: [],
  setCaretakers: () => {},

  caretakersSelected: [],
  setCaretakersSelected: () => {},

  schedulesState: [],
  setSchedulesState: () => {},

  selectedSchedules: [],
  setSelectedSchedules: () => {},

  refetchAllSchedules: false,
  setRefetchAllSchedules: () => {},

  selectedRoutesIds: [],
  setSelectedRoutesIds: () => {},
  routes: [],
  routesLoading: true,
};

export const RoutesContext =
  createContext<IRoutesContext>(contextDefaultValues);

export const RoutesProvider = ({ children }) => {
  const [routesState, setRoutesState] = useState(
    contextDefaultValues.routesState
  );

  const [unselectedOrdersIds, setUnselectedOrdersIds] = useState(
    contextDefaultValues.unselectedOrdersIds
  );
  const [calendarStartDate, setCalendarStartDate] = useState(
    contextDefaultValues.calendarStartDate
  );
  const [calendarEndDate, setCalendarEndDate] = useState(
    contextDefaultValues.calendarEndDate
  );

  const [caretakers, setCaretakers] = useState(contextDefaultValues.caretakers);

  const [caretakersSelected, setCaretakersSelected] = useState(
    contextDefaultValues.caretakersSelected
  );
  const [selectedRoutesIds, setSelectedRoutesIds] = useState(
    contextDefaultValues.selectedRoutesIds
  );

  // Schedules
  const [schedulesState, setSchedulesState] = useState(
    contextDefaultValues.schedulesState
  );
  const [selectedSchedules, setSelectedSchedules] = useState(
    contextDefaultValues.selectedSchedules
  );
  const [refetchAllSchedules, setRefetchAllSchedules] = useState(
    contextDefaultValues.refetchAllSchedules
  );

  const fetchStartDate = useMemo(
    () =>
      calendarStartDate
        ? DateTime.fromJSDate(calendarStartDate)
            .startOf('week')
            .toJSDate()
            .toISOString()
        : null,
    [calendarStartDate]
  );
  const fetchEndDate = useMemo(
    () =>
      calendarEndDate
        ? DateTime.fromJSDate(calendarEndDate)
            .startOf('week')
            .toJSDate()
            .toISOString()
        : null,
    [calendarEndDate]
  );

  const {
    data: routes = [],
    isFetching,
    refetch,
  } = useRoutes(
    {
      startDate: fetchStartDate,
      endDate: fetchEndDate,
    },
    { enabled: false }
  );

  const refetchAllRoutes = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (routes && routes?.length) {
      setUnselectedOrdersIds(getOrdersFromRoutes(routes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  return (
    <RoutesContext.Provider
      value={{
        routesState,
        setRoutesState,

        unselectedOrdersIds,
        setUnselectedOrdersIds,

        calendarStartDate,
        setCalendarStartDate,

        calendarEndDate,
        setCalendarEndDate,

        refetchAllRoutes,

        caretakers,
        setCaretakers,

        caretakersSelected,
        setCaretakersSelected,

        selectedRoutesIds,
        setSelectedRoutesIds,

        selectedSchedules,
        setSelectedSchedules,

        schedulesState,
        setSchedulesState,

        refetchAllSchedules,
        setRefetchAllSchedules,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        routes: routes as any[],
        routesLoading: isFetching,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutesContext = () => {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error('useRoutesContext must be used within a RoutesProvider');
  }

  return context;
};
