import getConfig from 'next/config';
import { getUserAccessToken } from './auth';

let API_URL = '';
let PUBLIC_RUNTIME_CONFIG = null;

if (getConfig()) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PUBLIC_RUNTIME_CONFIG = getConfig().publicRuntimeConfig.NODE_ENV;
  API_URL = getConfig().publicRuntimeConfig.API_ENDPOINT;
}

const getApiUrl = () => {
  if (typeof window === 'undefined' || !API_URL?.includes('service')) {
    return API_URL;
  }
  const serviceName = new URL(window.location.href).host.split('.')[0];
  return API_URL?.replace('service', serviceName);
};

export const url = getApiUrl();

export const getHeaders = () => {
  const access_token = getUserAccessToken();
  return {
    'Access-Control-Allow-Origin': '*',
    Accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };
};

export const getHeadersPDF = () => {
  const access_token = getUserAccessToken();
  return {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/pdf',
    'Content-Type': 'application/pdf',
    Authorization: `Bearer ${access_token}`,
  };
};
