import Icon, { SettingFilled } from '@ant-design/icons';

const IconImage = ({ style, imageUrl }) => {
  const componentImage = () => <img src={imageUrl} style={style} />;

  return imageUrl === 'settings' ? (
    <SettingFilled
      style={{
        color: '#193a63',
        width: 20,
        height: 20,
        fontSize: 20,
        marginBottom: 5,
      }}
    />
  ) : (
    <Icon component={componentImage} />
  );
};

export default IconImage;
