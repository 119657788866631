import moment from 'moment';
import colors from './colors';
import { compareDateTimes } from './date';
import {
  BREAK_DURATION,
  BREAK_TIME,
  POST_ROUTE_CO,
  PRE_ROUTE_CO,
  TIME_BETWEEN_TRIPS,
} from 'src/constants';

export const formatCaretakersOptions = (caretakers = []) =>
  caretakers?.map(({ id, firstName, lastName, wage }) => ({
    id: id as string,
    name: `${firstName} ${lastName || ''}`,
    wage: wage || 0,
  }));

export const getOrdersFromRoutes = (routes = []) =>
  routes.reduce((acc, route) => {
    let orders = [];
    for (const event of route.events) {
      const ordersIds = event.orders.map((order) => order.id);
      orders = [...orders, ...ordersIds];
    }
    return [...acc, ...orders];
  }, []);

export const formatRoutes = (
  routes = [],
  resource = 'day',
  resourceIdExtension = ''
) => {
  const sortedRoutes = routes.sort(
    (a, b) => new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime()
  );
  const allDates = sortedRoutes.map((route) =>
    moment(route.startsAt).format('DD/MM/YYYY')
  );
  const uniqueDates = [...new Set(allDates)];

  let allRoutes = [];
  for (const date of uniqueDates) {
    const groupRoutes = sortedRoutes.filter(
      (route) => date === moment(route.startsAt).format('DD/MM/YYYY')
    );
    const sortedGroupRoutes = groupRoutes.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    let placeNumber = 0;
    const routesWithNumber = sortedGroupRoutes.map((route) => {
      placeNumber += 1;
      return {
        ...route,
        placeNumber,
      };
    });
    allRoutes = [...allRoutes, ...routesWithNumber];
  }

  return allRoutes.map((route) => {
    const {
      id,
      dayOfWeek,
      caretakers,
      name = '',
      startsAt,
      events,
      shift,
    } = route;
    events.sort((eventA, eventB) => (eventA.endsAt > eventB.endsAt ? 1 : -1));
    const caretakerResource = caretakers?.[0]?.id;
    const routeDuration = events.reduce((acc, event, currentIndex) => {
      let duration = 0;
      const { category = '' } = event;
      if (category === 'break') {
        duration = BREAK_DURATION;
      } else if (category.includes('route-co')) {
        duration = 10;
      } else {
        duration = event.duration;
      }
      return (
        acc +
        duration +
        (currentIndex < events.length - 2 ? TIME_BETWEEN_TRIPS : 0)
      );
    }, 0);

    const start = startsAt;
    const end = moment(startsAt)
      .add(routeDuration, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss');

    const resourceId =
      resource === 'caretaker'
        ? caretakerResource + resourceIdExtension
        : dayOfWeek;

    return {
      id,
      resourceId,
      title: name || 'No Name',
      start,
      end,
      isRoute: true,
      routeDuration,
      shift,
    };
  });
};

export const formatRoutesWithColor = (routes = [], routesIdSelected = []) =>
  routes.map((route) => {
    const include = routesIdSelected.includes(route.id);

    const eventColor = include ? { color: 'gray' } : {};
    const event = {
      ...route,
      ...eventColor,
    };

    return event;
  });

type FormattedSchedule = {
  id: string;
  resourceId: string;
  title: string;
  start: string;
  end: string;
  color: typeof colors;
};

export const formatSchedules = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schedules = [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSchedules = [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routesEvents = [],
  resourceIdExtension = '-occassion'
): FormattedSchedule[] => {
  const occassionColors = {
    vacation: colors.blue,
    training: colors.gray,
    longshift: colors.green,
    shortshift: colors.olive_green,
    illness: colors.purple,
    team_meeting: colors.red,
    unavailable: colors.yellow,
    officeshift: colors.seaBlue,
    standby: colors.orange,
  };

  // TODO: add correct duration for illness
  const occasionTimes = {
    vacation: '00:00',
    training: '08:00',
    longshift: '08:00',
    shortshift: '04:00',
    illness: '00:00',
    team_meeting: '01:00',
    unavailable: '00:00',
    officeshift: '08:00',
    standby: '00:00',
  };

  return schedules.reduce((prev, schedule) => {
    const { id, name, startsAt, endsAt, employees = [], eventType } = schedule;

    const start = moment(startsAt).utcOffset(0).format();

    const end = moment(endsAt)
      .utcOffset(0)
      .format()
      .replace('00:00:00', '23:59:59'); // set end to 23:59:59 if end is 00:00:00 to avoid calendar bug

    const getOccasionDuration = (employee) => {
      if (eventType === 'vacation') {
        if (!employee.weeklyHours) return '00:00';
        const duration = employee.weeklyHours / 5;
        return moment().startOf('day').add(duration, 'hours').format('HH:mm');
      } else if (eventType === 'illness') {
        // get all the routes of the same day for the employee
        const routes = routesEvents.filter(
          (route) =>
            route.resourceId === employee.id &&
            compareDateTimes(route.start, start)
        );
        // get the duration of all the routes
        const routesDuration = routes.reduce(
          (acc, route) => acc + route.routeDuration,
          0
        );
        // convert minutes to hours in format HH:mm
        const duration = moment()
          .startOf('day')
          .add(routesDuration, 'minutes')
          .format('HH:mm');
        return duration;
      }
      return occasionTimes[eventType];
    };

    if (employees.length) {
      const schedulesByEmployee = employees.reduce(
        (prev, employee) => [
          ...prev,
          {
            id,
            resourceId: `${employee.id}${resourceIdExtension}`,
            title: name || 'No Name',
            start,
            end,
            color: selectedSchedules?.includes(id)
              ? occassionColors[eventType].selected
              : occassionColors[eventType].unselected,
            occasionDuration: getOccasionDuration(employee),
            eventType,
          },
        ],
        []
      );
      return [...prev, ...schedulesByEmployee];
    } else return [...prev];
  }, []);
};

// Calendar Routes
const createRouteCoordinationTime = (type) => {
  const id = Math.random();
  return {
    category: type,
    duration: 10,
    isCoordinationTime: true,
    isDisabled: true,
    checked: true,
    orderGroupId: id,
    key: id,
    id,
  };
};

const createBreakTimeRow = (startTime) => {
  const id = Math.random();

  return {
    datetime: moment(startTime).format('HH:mm').toString(),
    duration: BREAK_DURATION,
    isBreakTime: true,
    checked: true,
    key: id,
    id,
    category: 'break', // required to display the name in the table
  };
};

const getCoordinationTime = (data, selectedData, type) => {
  const coordinationTime = data.find((it) => it?.category === type);
  return (
    coordinationTime ||
    (selectedData.length && createRouteCoordinationTime(type))
  ); // if selectedData is empty, don't create coordination time
};

const getUnselectedData = ({ data, selectedRowKeys }) =>
  data.reduce((unselectedData, orderGroup, index) => {
    const isLastOrderGroup = index === data.length - 1;

    if (!selectedRowKeys.includes(orderGroup.id)) {
      unselectedData.push(orderGroup);
    }

    // Sort unselected data by trip number
    if (isLastOrderGroup) {
      unselectedData.sort((a, b) => a.tripNumber - b.tripNumber);
    }

    return unselectedData;
  }, []);

const getSelectedData = ({
  data,
  selectedRowKeys,
  hasBreakTime,
  breakTimeRow,
  startTime,
}) =>
  selectedRowKeys.reduce((selectedData, id, index) => {
    const orderGroup = data.find((it) => it.id === id);
    const isLastRow = index === selectedRowKeys.length - 1;

    if (orderGroup?.isBreakTime) {
      // Break time was added by the Back
      selectedData.push(orderGroup);
    } else if (hasBreakTime && breakTimeRow?.id === id) {
      // Break time was added by the Front
      selectedData.push(breakTimeRow);
    } else if (!orderGroup?.isCoordinationTime) {
      // remove coordination time from selected data
      selectedData.push(orderGroup);
    }

    // remove falsy values and add coordination times
    if (isLastRow) {
      const newSelectedData = selectedData.filter(Boolean); // remove falsy values

      const totalTimeWithoutBreak = newSelectedData.reduce(
        (acc, orderGroup, currentIndex) => {
          return (
            acc +
            orderGroup.duration +
            (currentIndex < newSelectedData.length - 2 ? TIME_BETWEEN_TRIPS : 0)
          );
        },
        0
      );

      if (totalTimeWithoutBreak >= BREAK_TIME && !hasBreakTime) {
        const breakTimeRow = createBreakTimeRow(startTime);
        newSelectedData.push(breakTimeRow);
      }

      // Add pre coordination time to the first row of selected data
      const preRouteCo = getCoordinationTime(data, selectedData, PRE_ROUTE_CO);
      preRouteCo && newSelectedData.unshift(preRouteCo);

      // Add post coordination time to the last row of selected data
      const postRouteCo = getCoordinationTime(
        data,
        selectedData,
        POST_ROUTE_CO
      );
      postRouteCo && newSelectedData.push(postRouteCo);

      return newSelectedData; // return new selected data with coordination times
    }

    return selectedData;
  }, []);

const removeDuplicateById = (acc, curr) => {
  const isDuplicate = acc.find((it) => it.id === curr.id);
  return isDuplicate ? acc : [...acc, curr];
};

export const formatOrderGroupData = ({
  data,
  startingTime,
  selectedRowKeys,
  breakTimeRow,
}) => {
  let startTime = startingTime?.toDate();

  // This is to know if frontend already added break time or not
  const hasBreakTime = !!breakTimeRow?.id;

  const selectedData = getSelectedData({
    data,
    selectedRowKeys,
    hasBreakTime,
    breakTimeRow,
    startTime,
  });
  const unselectedData = getUnselectedData({ data, selectedRowKeys }); // unselected data is sorted by trip number

  const totalTimeWithoutBreak = selectedData.reduce(
    (acc, orderGroup, currentIndex) =>
      !orderGroup?.isBreakTime
        ? acc +
          orderGroup.duration +
          (currentIndex < selectedData.length - 2 ? TIME_BETWEEN_TRIPS : 0)
        : acc, // add 5 minutes between trips
    0
  );

  const mergedData = [...selectedData, ...unselectedData]
    .filter(Boolean)
    .reduce(removeDuplicateById, []);

  const rows = startTime
    ? mergedData.reduce((acc, orderGroup, currentIndex) => {
        let datetime = '-';
        const key = orderGroup?.orderGroupId;
        const duration = orderGroup.duration;
        const isSelectedData = selectedData.some((it) => it?.id === key);

        if (!orderGroup?.isBreakTime && isSelectedData && startTime) {
          const currentTime = moment(startTime).add(
            duration +
              (currentIndex < selectedData.length - 1 ? TIME_BETWEEN_TRIPS : 0),
            'minutes'
          );
          datetime = moment(startTime).format('HH:mm').toString();
          startTime = currentTime;
        }

        if (orderGroup?.isBreakTime) {
          if (totalTimeWithoutBreak < BREAK_TIME) return acc; // don't add break time if total time is less than 6 hours

          const breakTime = moment(startTime).add(
            BREAK_DURATION +
              (currentIndex < selectedData.length - 2 ? TIME_BETWEEN_TRIPS : 0),
            'minutes'
          );
          const breakTimeRow = {
            ...orderGroup,
            datetime: moment(startTime).format('HH:mm').toString(),
            duration: BREAK_DURATION,
            isDisabled: true,
            checked: true,
            key,
          };
          startTime = breakTime;
          return [...acc, breakTimeRow];
        }

        acc.push({
          key,
          ...orderGroup,
          datetime,
          duration,
          checked: selectedData.some((it) => it?.id === key),
          isDisabled: orderGroup?.isCoordinationTime,
        });

        return acc;
      }, [])
    : [];

  return rows;
};

export const getOrders = (orderGroupData, orderGroupSelected) => {
  const orderGroupSelectedIds = orderGroupSelected.map(
    (orderGroupSelected) => orderGroupSelected.id
  );

  const orderGroups = orderGroupData.filter((orderGroup) =>
    orderGroupSelectedIds.includes(orderGroup?.orderGroupId)
  );

  let orders = [];

  for (const orderGroup of orderGroups) {
    const ordersIds = orderGroup.orders.map((order) => ({ id: order.id }));
    orders = [...orders, ...ordersIds];
  }
  return orders;
};

export const convertParamsToString = (params) => {
  if (!Object.keys(params).length) return '';

  const stringPath = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((value) => `${key}=${value}`).join('&');
      }
      return `${key}=${params[key]}`;
    })
    .join('&');

  return `?${stringPath}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyValues = (obj: any) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      typeof value !== 'object'
    ) {
      acc[key] = value;
    }
    if (value && typeof value === 'object') {
      if (
        Object.values(value).some(
          (x) => x !== null && x !== undefined && x !== ''
        )
      ) {
        acc[key] = removeEmptyValues(value);
      }
    }
    return acc;
  }, {});

export const removeEmptyValuesSafe = (obj: any) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    // Check for non-object values or non-empty strings
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      typeof value !== 'object'
    ) {
      acc[key] = value;
    } else if (Array.isArray(value)) {
      // Explicitly handle arrays
      const filteredArray = value
        .map(removeEmptyValuesSafe)
        .filter(
          (item) =>
            item !== null && item !== undefined && Object.keys(item).length > 0
        );
      if (filteredArray.length > 0) {
        acc[key] = filteredArray;
      }
    } else if (typeof value === 'object' && value !== null) {
      // Handle other objects
      const filteredObject = removeEmptyValuesSafe(value);
      if (Object.keys(filteredObject).length > 0) {
        acc[key] = filteredObject;
      }
    }
    return acc;
  }, {});
