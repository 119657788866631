import {
  ACCOUNT,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CODE_IS_VERIFIED,
  CREATE,
  READ,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_CODE,
  SAVE_CODE_IN_STORE,
  SIGN_UP,
} from '../actions/actionTypes';

const initialState = {
  signupForm: {
    company_name: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
    commune_id: '0',
    company_description: '0',
  },
  account: [],
  loading: false,
  verificationCode: null,
  email: '',
  isCodeVerified: false,
};

function account(state = initialState, action) {
  switch (action.type) {
    case `${SIGN_UP} ${CREATE} ${API_SUCCESS}`:
      return { ...state, account: action.payload, loading: false };
    case `${ACCOUNT} ${READ} ${API_SUCCESS}`:
    case `${ACCOUNT} ${READ} ${API_FAILURE}`:
    case `${RECOVER_PASSWORD_CODE} ${API_SUCCESS}`:
    case `${RECOVER_PASSWORD_CODE} ${API_FAILURE}`:
    case `${RECOVER_PASSWORD} ${API_FAILURE}`:
    case `${RECOVER_PASSWORD} ${API_SUCCESS}`:
      return { ...state, loading: false };
    case `${ACCOUNT} ${READ} ${API_PENDING}`:
    case `${SIGN_UP} ${CREATE} ${API_PENDING}`:
    case `${RECOVER_PASSWORD_CODE} ${API_PENDING}`:
    case `${RECOVER_PASSWORD} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${SIGN_UP} ${CREATE} ${API_FAILURE}`:
      return { ...state, loading: false };
    case SAVE_CODE_IN_STORE:
      return {
        ...state,
        verificationCode: action.payload.code,
        email: action.payload.email,
      };
    case CODE_IS_VERIFIED:
      return { ...state, isCodeVerified: true };
    default:
      return { ...state };
  }
}

export default account;
