import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CAREGIVER,
  COUNTRY,
  CREATE,
  DELETE,
  INSURANCE_COMPANY,
  NATIONALITY,
  ORDER,
  PATIENT,
  READ,
  READ_SINGLE,
  SERVICE,
  SGB05,
  SGB11,
  TIME,
  UPDATE,
} from 'actions/actionTypes';

const initialState = {
  nationalities: [],
  countries: [],
  insurances: [],
  patients: [],
  orders: [],
  order: {
    service: {},
    patient: {},
  },
  patient: {
    address: {},
    accessData: {},
    healthConstitution: {},
  },
  services: [],
  loading: false,
  caregivers: [],
};

const SGB11Condition = (fullCode) =>
  fullCode.substring(2, 4) === '01' &&
  (fullCode.substring(4, 6) === '02' || fullCode.substring(4, 6) === '07');

const filterServicesBySGB = (services, sgb, type = null) => {
  const filteredServices = services.filter(
    (service) => service.sourceList === sgb
  );
  if (sgb === SGB05) {
    return filteredServices;
  } else {
    if (type === SERVICE) {
      return filteredServices.filter(({ GPOS: { fullCode } }) =>
        SGB11Condition(fullCode)
      );
    } else {
      return filteredServices.filter(
        ({ GPOS: { fullCode } }) => !SGB11Condition(fullCode)
      );
    }
  }
};

const filterOrdersBySGB = (orders, sgb, type = null) => {
  const filteredOrders = orders.filter(
    (order) => order.service.sourceList === sgb
  );

  if (sgb === SGB05) {
    return filteredOrders;
  } else {
    if (type === SERVICE) {
      return filteredOrders.filter(({ service }) =>
        SGB11Condition(service.GPOS.fullCode)
      );
    } else {
      return filteredOrders.filter(
        ({ service }) => !SGB11Condition(service.GPOS.fullCode)
      );
    }
  }
};

function patient(state = initialState, action) {
  switch (action.type) {
    case `${PATIENT} ${CREATE} ${API_PENDING}`:
    case `${ORDER} ${CREATE} ${API_PENDING}`:
    case `${PATIENT} ${DELETE} ${API_PENDING}`:
    case `${ORDER} ${DELETE} ${API_PENDING}`:
    case `${PATIENT} ${READ_SINGLE} ${API_PENDING}`:
    case `${ORDER} ${READ_SINGLE} ${API_PENDING}`:
    case `${PATIENT} ${READ} ${API_PENDING}`:
    case `${ORDER} ${READ} ${API_PENDING}`:
    case `${ORDER} ${READ} ${SGB05} ${API_PENDING}`:
    case `${ORDER} ${READ} ${SGB11} ${SERVICE} ${API_PENDING}`:
    case `${ORDER} ${READ} ${SGB11} ${TIME} ${API_PENDING}`:
    case `${NATIONALITY} ${READ} ${API_PENDING}`:
    case `${COUNTRY} ${READ} ${API_PENDING}`:
    case `${SERVICE} ${READ} ${API_PENDING}`:
    case `${SERVICE} ${READ} ${SGB05} ${API_PENDING}`:
    case `${SERVICE} ${READ} ${SGB11} ${SERVICE} ${API_PENDING}`:
    case `${SERVICE} ${READ} ${SGB11} ${TIME} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${PATIENT} ${CREATE} ${API_SUCCESS}`:
    case `${PATIENT} ${DELETE} ${API_SUCCESS}`:
    case `${PATIENT} ${CREATE} ${API_FAILURE}`:
    case `${PATIENT} ${DELETE} ${API_FAILURE}`:
    case `${PATIENT} ${READ_SINGLE} ${API_FAILURE}`:
    case `${PATIENT} ${UPDATE} ${API_SUCCESS}`:
    case `${PATIENT} ${UPDATE} ${API_FAILURE}`:
    case `${PATIENT} ${READ} ${API_FAILURE}`:
    case `${SERVICE} ${READ} ${API_FAILURE}`:
    case `${SERVICE} ${READ} ${SGB05} ${API_FAILURE}`:
    case `${SERVICE} ${READ} ${SGB11} ${SERVICE} ${API_FAILURE}`:
    case `${SERVICE} ${READ} ${SGB11} ${TIME} ${API_FAILURE}`:
    case `${ORDER} ${CREATE} ${API_SUCCESS}`:
    case `${ORDER} ${DELETE} ${API_SUCCESS}`:
    case `${ORDER} ${CREATE} ${API_FAILURE}`:
    case `${ORDER} ${DELETE} ${API_FAILURE}`:
    case `${ORDER} ${READ_SINGLE} ${API_FAILURE}`:
    case `${ORDER} ${UPDATE} ${API_SUCCESS}`:
    case `${ORDER} ${UPDATE} ${API_FAILURE}`:
    case `${ORDER} ${READ} ${API_FAILURE}`:
    case `${ORDER} ${READ} ${SGB05} ${API_FAILURE}`:
    case `${ORDER} ${READ} ${SGB11} ${SERVICE} ${API_FAILURE}`:
    case `${ORDER} ${READ} ${SGB11} ${TIME} ${API_FAILURE}`:
    case `${NATIONALITY} ${READ} ${API_FAILURE}`:
    case `${COUNTRY} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${PATIENT} ${ADD}`:
      return {
        ...state,
        patient: {
          birthdate: '1999-01-01',
          initialTakeUp: '1999-01-01',
          initialMeeting: '1999-01-01',
          endOfCareDate: '1999-01-01',
          deceased: '1999-01-01',
          address: {},
          accessData: {},
          healthConstitution: {},
          contractActiveData: {},
          contractEndData: {},
          billingCodes: {},
        },
        loading: false,
      };
    case `${ORDER} ${ADD}`:
      return {
        ...state,
        order: {
          service: {},
          patient: {},
        },
        loading: false,
      };
    case `${PATIENT} ${READ} ${API_SUCCESS}`:
      return { ...state, patients: action.payload, loading: false };
    case `${SERVICE} ${READ} ${API_SUCCESS}`:
      return { ...state, services: action.payload, loading: false };
    case `${SERVICE} ${READ} ${SGB05} ${API_SUCCESS}`:
      return {
        ...state,
        services: filterServicesBySGB(action.payload, SGB05),
        loading: false,
      };
    case `${SERVICE} ${READ} ${SGB11} ${SERVICE} ${API_SUCCESS}`:
      return {
        ...state,
        services: filterServicesBySGB(action.payload, SGB11, SERVICE),
        loading: false,
      };
    case `${SERVICE} ${READ} ${SGB11} ${TIME} ${API_SUCCESS}`:
      return {
        ...state,
        services: filterServicesBySGB(action.payload, SGB11, TIME),
        loading: false,
      };
    case `${ORDER} ${READ} ${API_SUCCESS}`:
      return { ...state, orders: action.payload, loading: false };
    case `${ORDER} ${READ} ${SGB05} ${API_SUCCESS}`:
      return {
        ...state,
        orders: filterOrdersBySGB(action.payload, SGB05),
        loading: false,
      };
    case `${ORDER} ${READ} ${SGB11} ${SERVICE} ${API_SUCCESS}`:
      return {
        ...state,
        orders: filterOrdersBySGB(action.payload, SGB11, SERVICE),
        loading: false,
      };
    case `${ORDER} ${READ} ${SGB11} ${TIME} ${API_SUCCESS}`:
      return {
        ...state,
        orders: filterOrdersBySGB(action.payload, SGB11, TIME),
        loading: false,
      };
    case `${NATIONALITY} ${READ} ${API_SUCCESS}`:
      return { ...state, nationalities: action.payload, loading: false };
    case `${COUNTRY} ${READ} ${API_SUCCESS}`:
      return { ...state, countries: action.payload, loading: false };
    case `${INSURANCE_COMPANY} ${READ} ${API_SUCCESS}`:
      return { ...state, insurances: action.payload, loading: false };
    case `${CAREGIVER} ${READ} ${API_SUCCESS}`:
      return { ...state, caregivers: action.payload, loading: false };
    case `${PATIENT} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        patient: action.payload,
      };
    case `${ORDER} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    default:
      return { ...state };
  }
}

export default patient;
