import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

let language = 'en';

export const getLanguage = () => {
  return language;
};

export const useLanguage = () => {
  const { lang } = useTranslation();

  useEffect(() => {
    language = lang;
  }, [lang]);

  return { lang, setLanguage };
};
