import * as Sentry from '@sentry/browser';
import { User } from '@sentry/browser';
import { getServiceName } from 'api/http-common';

export const setUser = (user: User | null) => {
  if (user) {
    user.segment = getServiceName();
  }
  Sentry.setUser({
    ...user,
  });
};
