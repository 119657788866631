import { configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import getConfig from 'next/config';
import { Action, compose } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import apiMiddleware from './middleware/apiMiddleware';
import notificationsMiddleware from './middleware/notificationsMiddleware';
import performanceMiddleware from './middleware/performanceMiddleware';
import reducers from './reducers';

let dev = null;
if (getConfig()) {
  dev = getConfig().publicRuntimeConfig.NODE_ENV;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const coreMiddlewares = [thunk, apiMiddleware, notificationsMiddleware];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const devMiddlewares = dev === 'development' ? [performanceMiddleware] : [];

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    if (state.count) nextState.count = state.count; // preserve count value on client side navigation
    return nextState;
  } else {
    return reducers(state, action);
  }
};

export const makeStore = (initialState = {}) => {
  return configureStore({
    reducer,
    middleware: [...coreMiddlewares],
    preloadedState: initialState,
  });
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const wrapper = createWrapper<AppStore>(makeStore);
