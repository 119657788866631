import Router from 'next/router';

import { Row } from 'antd';
import { useTranslations } from 'hooks/translations/useTranslations';
import { useDispatch } from 'react-redux';

import { logout } from 'actions/account';
import IconImage from 'atoms/IconImage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from 'assets/main.module.less';

import { Dispatch } from 'redux';

const basePath = '../../../static/image_icons/';

interface IconMenuProps {
  title: string;
  image: string;
  route: string;
}

const IconMenu = ({ title, image, route }: IconMenuProps) => {
  const {
    home: { t },
  } = useTranslations();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<Dispatch<any>>();

  const goToRoute = () => {
    route === 'logout' && dispatch(logout());
    route && Router.push(route);
  };

  return (
    <Row onClick={goToRoute} style={{ marginTop: 2 }} align="middle">
      <IconImage
        imageUrl={image}
        style={{ width: '20px', height: '20px', marginBottom: '5px' }}
      />
      <span className={styles.menu_item}>{t(title)}</span>
    </Row>
  );
};

const getItem = (title, route = title) => ({
  key: title,
  label: (
    <IconMenu
      route={route}
      image={title === 'settings' ? title : `${basePath}${title}.png`}
      title={title}
    />
  ),
});

export const externalContactItems = [
  {
    title: 'health_insurance',
    image: `${basePath}health_insurance.png`,
    route: '/health_insurance',
  },
  {
    title: 'care_insurance',
    image: `${basePath}care_insurance.png`,
    route: '/care_insurance',
  },
  {
    title: 'municipalities',
    image: `${basePath}municipalities.png`,
    route: '/municipalities',
  },
  { title: 'vehicles', image: `${basePath}vehicles.png`, route: '/vehicles' },
  {
    title: 'government_aid',
    image: `${basePath}government_aid.png`,
    route: '/government',
  },
  {
    title: 'pharmacies',
    image: `${basePath}pharmacies.png`,
    route: '/pharmacies',
  },
  { title: 'doctors', image: `${basePath}doctors.png`, route: '/doctor' },
  {
    title: 'personal_caregiver',
    image: `${basePath}personal_caregiver.png`,
    route: '/caregiver',
  },
];

export const imagesList = [
  {
    type: 'group',
    children: [getItem('calendar', '/tasks')],
  },
  {
    type: 'group',
    children: [
      getItem('patients', '/patients'),
      {
        ...getItem('external_contacts', '/external_contacts'),
        children: externalContactItems.map(({ title, route }) =>
          getItem(title, route)
        ),
      },
      getItem('caretakers', '/caretakers'),
    ],
  },
  {
    type: 'group',
    children: [
      getItem('patient_contracts', '/patient_contracts'),
      getItem('cost_estimation', '/cost_estimate'),
      getItem('billing', '/itemised_billing'),
      {
        ...getItem('financial_reporting', '#'),
        children: [
          getItem('accounting', '/account'),
          getItem('controlling', '/controlling'),
        ],
      },
    ],
  },
  {
    type: 'group',
    children: [getItem('settings', '/settings'), getItem('logout')],
  },
];
