import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CARE_TAKER,
  CREATE,
  DELETE,
  ORDER,
  READ,
  READ_SINGLE,
  ROUTE,
  SELECT,
  SERVICE,
  TASK,
} from '../actions/actionTypes';

const initialState = {
  caretakers: [],
  orders: [],
  services: [],
  routes: [],
  selectedTasks: [],
  loading: false,
};

function route(state = initialState, action) {
  switch (action.type) {
    case `${ROUTE} ${CREATE} ${API_PENDING}`:
    case `${ROUTE} ${READ_SINGLE} ${API_PENDING}`:
    case `${ROUTE} ${READ} ${API_PENDING}`:
    case `${ROUTE} ${DELETE} ${API_PENDING}`:
    case `${TASK} ${SELECT}`:
      return { ...state, selectedTasks: action.payload };
    case `${ROUTE} ${CREATE} ${API_SUCCESS}`:
    case `${ROUTE} ${CREATE} ${API_FAILURE}`:
    case `${ROUTE} ${READ_SINGLE} ${API_FAILURE}`:
    case `${ROUTE} ${READ} ${API_FAILURE}`:
    case `${ROUTE} ${DELETE} ${API_SUCCESS}`:
    case `${ROUTE} ${DELETE} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${ROUTE} ${READ} ${API_SUCCESS}`:
      return { ...state, routes: action.payload, loading: false };
    case `${ROUTE} ${READ_SINGLE} ${API_SUCCESS}`:
      return { ...state, route: action.payload, loading: false };
    case `${SERVICE} ${READ_SINGLE} ${API_SUCCESS}`:
      return { ...state, service: action.payload, loading: false };
    case `${SERVICE} ${READ} ${API_SUCCESS}`:
      return { ...state, services: action.payload, loading: false };
    case `${ORDER} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${ORDER} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${ORDER} ${READ} ${API_SUCCESS}`:
      return { ...state, orders: action.payload, loading: false };
    case `${CARE_TAKER} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${CARE_TAKER} ${READ} ${API_SUCCESS}`:
      return { ...state, caretakers: action.payload, loading: false };
    default:
      return { ...state };
  }
}

export default route;
