import { connect } from 'react-redux';

import { hideModal } from 'actions/modal';

const Container = (props) => props.children({ ...props });

const mapStateToProps = ({ modal }) => ({ ...modal });

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

export const ModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
export const withModal = connect(mapStateToProps, mapDispatchToProps);
