import useOriginalTranslation from 'next-translate/useTranslation';

const namespaces = [
  'account',
  'accounting',
  'calendar',
  'caregiver',
  'caretaker',
  'common',
  'controlling',
  'days',
  'delete',
  'doctor',
  'health_insurance',
  'home',
  'itemised_billing',
  'patient',
  'patientContracts',
  'pharmacy',
  'settings',
  'topbar',
  'vehicle',
] as const;

type NamespaceDict = Record<
  (typeof namespaces)[number],
  ReturnType<typeof useOriginalTranslation>
> & {
  t: ReturnType<typeof useOriginalTranslation>;
};

export const useTranslations: () => NamespaceDict = () => {
  return namespaces.reduce(
    (trans, it) => ({
      ...trans,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      [it]: useOriginalTranslation(it),
    }),
    {
      t: useOriginalTranslation(),
    }
  ) as NamespaceDict;
};
