import { withRouter } from 'next/router';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslations } from 'hooks/translations/useTranslations';

import SwitchLanguage from 'molecules/SwitchLanguage';

const { Header } = Layout;
const { Title } = Typography;

const TopBar = ({ toggle, collapsed, router: { pathname } }) => {
  const {
    home: { t },
  } = useTranslations();
  const paths = getPaths(t);

  const getPageTitle = () => {
    const rootPath = pathname.split('/')[1];

    return paths[rootPath];
  };

  const menuProps = {
    style: { fontSize: '16px' },
    onClick: toggle,
  };

  return (
    <Header
      style={{
        background: '#F0F2F5',
        marginLeft: collapsed ? '80px' : '260px',
        padding: 20,
      }}
    >
      <Row gutter={[24, 24]}>
        <Col {...colTopBarLeft}>
          {collapsed ? (
            <MenuUnfoldOutlined {...menuProps} />
          ) : (
            <MenuFoldOutlined {...menuProps} />
          )}
          <Title
            level={4}
            style={{ marginLeft: '15px', display: 'inline-block' }}
          >
            {getPageTitle()}
          </Title>
        </Col>
        <Col {...colTopBarRight}>
          <Row align="middle" justify="end">
            <Col offset={1} lg={7} md={10} sm={12} xs={18}>
              <SwitchLanguage />
            </Col>

            <Col {...colIcons} />
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

const getPaths = (t) => ({
  patients: t('patients'),
  caretakers: t('caretakers'),
  health_insurance: t('health_insurance'),
  care_insurance: t('care_insurance'),
  municipalities: t('municipalities'),
  vehicles: t('vehicles'),
  pharmacies: t('pharmacies'),
  caregiver: t('personal_caregiver'),
  doctor: t('doctors'),
  government: t('government_aid'),
  tasks: t('calendar'),
  patient_contracts: t('patient_contracts'),
  itemised_billing: t('billing'),
  cost_estimate: t('cost_estimation'),
  settings: t('settings'),
  account: t('accounting'),
  // add other page titles here
});

const colTopBarLeft = {
  xs: 12,
};

const colTopBarRight = {
  xs: 12,
};

const colIcons = {
  xs: 4,
  sm: 3,
  md: 2,
};

export default withRouter(TopBar);
