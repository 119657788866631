import { memo } from 'react';

import dynamic from 'next/dynamic';

import { ModalContainer } from './ModalContainer';

const ModalPatientContract = dynamic(
  import('../patientContracts/modals/ModalPatientContract'),
  {
    loading: () => false,
  }
);

const MODAL_COMPONENTS = {
  PATIENT_CONTRACT: ModalPatientContract,
};

const ModalDisplayer = () => (
  <ModalContainer>
    {({ hideModal, modalProps, modalType }) => {
      if (!modalType) {
        return null;
      }

      const SpecificModal = MODAL_COMPONENTS[modalType];
      if (!SpecificModal) {
        console.warn('Modal load error');
        return null;
      }

      return <SpecificModal {...modalProps} hideModal={hideModal} />;
    }}
  </ModalContainer>
);

export default memo(ModalDisplayer);
