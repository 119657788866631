import Router from 'next/router';

import { notification } from 'antd';

import {
  ACCOUNT,
  API_REQUEST,
  CLEAN_USER_SERVICE,
  CODE_IS_VERIFIED,
  READ,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_CODE,
  RESET_PASSWORD_ACCOUNT,
  SAVE_CODE_IN_STORE,
} from './actionTypes';

import jwtDecode from 'jwt-decode';
import * as api from 'utils/api/account';
import { setAuth } from 'utils/auth';

const allowedRoles = ['manager'];

export const login = (form, callback) => (dispatch) => {
  const feature = `${ACCOUNT} ${READ}`;
  dispatch({
    type: `${feature} ${API_REQUEST}`,
    meta: {
      api: api.login(form),
      feature,
      callback: async (res) => {
        const accessToken = res.data.access_token;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const roles = jwtDecode<{ roles: any }>(accessToken).roles;
        // check if any value of roles is in allowedRoles
        if (roles.some((r) => allowedRoles.includes(r))) {
          setAuth(accessToken);
          await Router.push('home');
          notification.success({
            message: 'Willkommen',
            duration: 5,
          });
        } else {
          notification.error({
            message: 'Fehler',
            description: 'Sie haben keine Berechtigung',
          });
        }
        callback();
      },
      failureCallback: (res) => {
        notification.error({ message: res.TypeError, duration: 5 });
        callback();
      },
    },
  });
};

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAN_USER_SERVICE });
  notification.success({ message: 'Auf Wiedersehen', duration: 5 });
  Router.replace('/logout');
};

export const sendRecoveryCodeToEmail = (email) => (dispatch) => {
  const feature = `${RECOVER_PASSWORD_CODE}`;
  dispatch({
    type: `${feature} ${API_REQUEST}`,
    meta: {
      api: api.sendRecoveryCodeToEmail(email),
      feature,
      notifications: {
        successMessage: 'Wiederherstellungslink an E-Mail gesendet',
      },
      failureCallback: (res) => {
        notification.error({
          message: 'Benutzer mit entsprechender E-Mail nicht gefunden',
          duration: 3,
        });
      },
      callback: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success({
            message: 'Wiederherstellungslink an E-Mail gesendet',
            duration: 3,
          });
          dispatch({
            type: SAVE_CODE_IN_STORE,
            payload: { code: true, email },
          });
        }
      },
    },
  });
};

export const changeForgottenPassword =
  ({ email, token, newPassword }) =>
  (dispatch) => {
    const feature = `${RECOVER_PASSWORD}`;
    dispatch({
      type: `${feature} ${API_REQUEST}`,
      meta: {
        api: api.changeForgottenPassword({ email, token, newPassword }),
        feature,
        failureCallback: (res) => {
          notification.error({ message: res.message, duration: 3 });
        },
        callback: (res) => {
          if (res.status === 200 || res.status === 201) {
            notification.success({
              message: 'Password has been changed',
              duration: 3,
            });
            dispatch({ type: CODE_IS_VERIFIED });
            Router.replace('/login');
          }
        },
      },
    });
  };

export const passwordReset =
  (password, password_confirmation, callback) => (dispatch, getState) => {
    const { email } = getState().account;

    const feature = `${RESET_PASSWORD_ACCOUNT}`;
    dispatch({
      type: `${feature} ${API_REQUEST}`,
      meta: {
        api: api.passwordReset(password, password_confirmation, email),
        feature,
        notifications: {
          successMessage: 'Passwort wurde aktualisiert',
          failureMessage: 'Fehler beim Aktualisieren des Passworts',
        },
        callback,
      },
    });
  };

export const validateCode = () => (dispatch) => {
  dispatch({ type: CODE_IS_VERIFIED });
};

export const recoverPassword = () => (dispatch) => {
  dispatch({
    type: RECOVER_PASSWORD,
    payload: 'data sent to server',
  });
};
