import { DateTime } from 'luxon';
import moment from 'moment';

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const formatMinutesToHHMM = (minutes: number) => {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  return DateTime.fromObject({ hour, minute }).toFormat('HH:mm');
};

export const getDaysBetweenDates = (start: Date, end: Date) => {
  // TODO: fix this
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const days = Math.round((end - start) / MILLISECONDS_IN_DAY);
  return Array.from(
    { length: days },
    (_, i) => new Date(start.getTime() + i * MILLISECONDS_IN_DAY)
  );
};

export const changeDay = (date: Date, type: 'next' | 'prev'): Date => {
  const newDate = new Date(date);
  const add = type === 'next' ? 1 : -1;
  newDate.setDate(newDate.getDate() + add);
  return newDate;
};

export const changeMonth = (date: Date, type: 'next' | 'prev'): Date => {
  const newDate = new Date(date);
  const add = type === 'next' ? 1 : -1;
  newDate.setMonth(newDate.getMonth() + add);
  return newDate;
};

export const getWeekdayFromDate = (date) =>
  date.toLocaleString('en-us', { weekday: 'long' }).split(' ')[0].toLowerCase();

export const compareDateTimes = (date1, date2) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  date1Moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  date2Moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return date1Moment.isSame(date2Moment);
};

export const getOneMonthRange = (date = new Date()) => {
  const startsAt = moment(date).utc().startOf('month');
  const endsAt = moment(date).utc().endOf('month');

  return { startsAt, endsAt };
};

export const disabledDateOnWeekday = (current, weekday) => {
  const customDate = moment().format('YYYY-MM-DD');
  return (
    (current && current < moment(customDate, 'YYYY-MM-DD')) ||
    (Number.isInteger(weekday) &&
      moment(current, 'YYYY-MM-DD').day() != weekday)
  );
};

export const concatDateWithTime = (date, time, timeZone) => {
  if (!date || !time) return null;

  const currentTime = moment(time);
  const hours = currentTime.hours();
  const minutes = currentTime.minutes();
  const newDate = moment(date).set({ hours, minutes });

  return newDate;
};

export const getDefaultStartTimePicker = (
  hours = 0,
  minutes = 0,
  seconds = 0
) => moment().hours(hours).minutes(minutes).seconds(seconds);
