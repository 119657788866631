import { HIDE_MODAL, SHOW_MODAL } from './actionTypes';

export function showModal(modalType, modalProps) {
  return (dispatch) => {
    dispatch({
      type: SHOW_MODAL,
      modalType,
      modalProps,
      meta: false,
    });
  };
}

export function hideModal() {
  return (dispatch) => {
    dispatch({
      type: HIDE_MODAL,
    });
  };
}
