import {
  ADD,
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  CAREGIVER,
  COUNTRY,
  CREATE,
  DELETE,
  NATIONALITY,
  READ,
  READ_SINGLE,
  UPDATE,
} from '../actions/actionTypes';

const initialState = {
  nationalities: [],
  countries: [],
  caregiver: {
    address: {},
    bankAccount: {},
  },
  caregivers: [],
  loading: false,
};

function caregiver(state = initialState, action) {
  switch (action.type) {
    case `${CAREGIVER} ${CREATE} ${API_PENDING}`:
    case `${CAREGIVER} ${DELETE} ${API_PENDING}`:
    case `${CAREGIVER} ${READ_SINGLE} ${API_PENDING}`:
    case `${CAREGIVER} ${READ} ${API_PENDING}`:
    case `${NATIONALITY} ${READ} ${API_PENDING}`:
    case `${COUNTRY} ${READ} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${CAREGIVER} ${CREATE} ${API_SUCCESS}`:
    case `${CAREGIVER} ${DELETE} ${API_SUCCESS}`:
    case `${CAREGIVER} ${CREATE} ${API_FAILURE}`:
    case `${CAREGIVER} ${DELETE} ${API_FAILURE}`:
    case `${CAREGIVER} ${UPDATE} ${API_FAILURE}`:
    case `${CAREGIVER} ${READ} ${API_FAILURE}`:
    case `${NATIONALITY} ${READ} ${API_FAILURE}`:
    case `${COUNTRY} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${CAREGIVER} ${ADD}`:
      return {
        ...state,
        caregiver: {
          address: {},
          bankAccount: {},
        },
        loading: false,
      };
    case `${CAREGIVER} ${READ} ${API_SUCCESS}`:
      return { ...state, caregivers: action.payload, loading: false };
    case `${NATIONALITY} ${READ} ${API_SUCCESS}`:
      return { ...state, nationalities: action.payload, loading: false };
    case `${COUNTRY} ${READ} ${API_SUCCESS}`:
      return { ...state, countries: action.payload, loading: false };
    case `${CAREGIVER} ${READ_SINGLE} ${API_FAILURE}`:
    case `${CAREGIVER} ${UPDATE} ${API_SUCCESS}`:
    case `${CAREGIVER} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        caregiver: action.payload,
      };
    default:
      return { ...state };
  }
}

export default caregiver;
