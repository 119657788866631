import { notification } from 'antd';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import getConfig from 'next/config';

import { getUserAccessToken } from 'utils/auth';
import { setUser } from 'utils/sentry';

const config = getConfig();
const API_URL = config ? config.publicRuntimeConfig.API_ENDPOINT : '';

export const getServiceName = () =>
  new URL(window.location.href).host.split('.')[0];

const getApiUrl = () => {
  if (typeof window === 'undefined' || !API_URL?.includes('service')) {
    return API_URL;
  }
  const serviceName = getServiceName();
  return API_URL.replace('service', serviceName);
};

const getHeaders = () => {
  const access_token = getUserAccessToken();
  if (access_token) {
    const { email } = jwtDecode<{ email: string }>(access_token);
    setUser({ email });
  } else {
    setUser(null);
  }

  return {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${access_token}`,
  };
};

const apiClient = axios.create({
  baseURL: getApiUrl(),
  headers: getHeaders(),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
apiClient.interceptors.request.use((config: any) => {
  config.headers = { ...config.headers, ...getHeaders() };
  return config;
});

apiClient.interceptors.response.use(
  (res) => res.data,
  ({ response }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (!response) {
      return Promise.reject(response);
    }
    const { data, status } = response;
    // notification.error({
    //   message: 'Error',
    //   description: data?.message || 'Something went wrong',
    // });
    status == 401 && window?.location.replace('/logout');
    return Promise.reject(response);
  }
);

export const apiClientPDF = axios.create({
  baseURL: getApiUrl(),
  headers: { ...getHeaders(), Accept: 'application/pdf' },
  responseType: 'blob',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
apiClientPDF.interceptors.request.use((config: any) => {
  config.headers = { ...config.headers, ...getHeaders() };
  return config;
});

export const downloadAsPDFHandler = (res) => {
  const filename = res.headers['content-disposition'].split('=')[1];
  const formattedFilename = filename.substring(1, filename.length - 1);
  const blob = new Blob([res.data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = formattedFilename;
  link.click();
};

export const blobErrorHandler = (err) => {
  const reader = new FileReader();
  reader.readAsText(err?.response?.data);
  reader.onload = () => {
    notification.error({
      message: 'Error',
      description: JSON.parse(reader.result?.toString() as string).message,
    });
  };
};

export default apiClient;
