import apiClient from 'api/http-common';
import { convertParamsToString } from 'utils/formats';

const path = '/routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoutes = (params): Promise<any[]> => {
  const stringParams = params ? convertParamsToString(params) : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return apiClient.get(`${path}${stringParams}`) as unknown as Promise<any[]>;
};

export const saveRoute = (params) => apiClient.post(path, params);

export const copyRoutes = (params) => apiClient.post(`${path}/copy`, params);

export const deleteRoute = (id) => apiClient.delete(`/routes/${id}`);

export const markRouteOrderAsCompleted = (routeId, orderId) =>
  apiClient.post(`/routes/${routeId}/order/${orderId}`);

export const deleteAdhoc = (routeId, orderId) =>
  apiClient.delete(`/routes/${routeId}/order/${orderId}`);
