import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useLanguage } from 'hooks/translations/useLanguage';
import { useTranslations } from 'hooks/translations/useTranslations';
import moment from 'moment';

const SwitchLanguage = () => {
  const {
    common: { t },
  } = useTranslations();
  const { lang, setLanguage } = useLanguage();
  const items = getItems(t);

  const handleChange = async (e) => {
    const locale = e.target.value;
    await setLanguage(locale);
    moment.locale(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="language-label">{t('select_language')}</InputLabel>
      <Select
        labelId="language-label"
        id="language-dropdown"
        value={items.find((item) => item.value === lang)?.value || ''}
        label={t('select_language')}
        onChange={handleChange}
        style={{ backgroundColor: 'white' }}
      >
        {items.map((item) => (
          <MenuItem value={item.value} key={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const getItems = (t) => [
  { label: t('germany'), key: 'de', value: 'de' },
  { label: t('english'), key: 'en', value: 'en' },
  { label: t('spanish'), key: 'es', value: 'es' },
];

export default SwitchLanguage;
