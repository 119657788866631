import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  COUNTRY,
  CREATE,
  DELETE,
  NATIONALITY,
  PHARMACY,
  READ,
  READ_SINGLE,
  UPDATE,
} from '../actions/actionTypes';

const initialState = {
  nationalities: [],
  countries: [],
  pharmacies: [],
  pharmacy: {
    address: {},
  },
  loading: false,
};

function pharmacy(state = initialState, action) {
  switch (action.type) {
    case `${PHARMACY} ${CREATE} ${API_PENDING}`:
    case `${PHARMACY} ${DELETE} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${PHARMACY} ${CREATE} ${API_SUCCESS}`:
    case `${PHARMACY} ${DELETE} ${API_SUCCESS}`:
      return { ...state, loading: false };
    case `${PHARMACY} ${CREATE} ${API_FAILURE}`:
    case `${PHARMACY} ${READ_SINGLE} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${PHARMACY} ${READ} ${API_PENDING}`:
    case `${NATIONALITY} ${READ} ${API_PENDING}`:
    case `${PHARMACY} ${READ_SINGLE} ${API_PENDING}`:
      return { ...state, loading: true };
    case `${PHARMACY} ${READ} ${API_SUCCESS}`:
      return { ...state, pharmacies: action.payload, loading: false };
    case `${PHARMACY} ${READ} ${API_FAILURE}`:
    case `${PHARMACY} ${UPDATE} ${API_SUCCESS}`:
    case `${PHARMACY} ${UPDATE} ${API_FAILURE}`:
    case `${PHARMACY} ${DELETE} ${API_FAILURE}`:
    case `${NATIONALITY} ${READ} ${API_FAILURE}`:
      return { ...state, loading: false };
    case `${NATIONALITY} ${READ} ${API_SUCCESS}`:
      return { ...state, nationalities: action.payload, loading: false };
    case `${COUNTRY} ${READ} ${API_SUCCESS}`:
      return { ...state, countries: action.payload, loading: false };
    case `${PHARMACY} ${READ_SINGLE} ${API_SUCCESS}`:
      return {
        ...state,
        loading: false,
        pharmacy: action.payload,
      };
    default:
      return { ...state };
  }
}

export default pharmacy;
