import Router from 'next/router';
import React, { useState } from 'react';

import { Divider, Layout } from 'antd';

import styles from 'assets/main.module.less';
import ModalDisplayer from 'components/modals/ModalDisplayer';
import FooterNav from 'components/navbar/FooterNav';
import SideBar from 'components/navbar/SideBar';
import TopBar from 'components/navbar/TopBar';

const { Sider, Content } = Layout;

const MainPage = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const onCollapse = (collapsed) => setCollapsed(collapsed);
  const toggle = () => setCollapsed(!collapsed);

  const marginContent = () => ({
    marginLeft: `${collapsed ? '80px' : '260px'}`,
    minHeight: '90vh',
  });

  const goToHomePage = () => Router.replace('/home');

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        trigger={null}
        breakpoint="xl"
        collapsedWidth="80"
        onCollapse={onCollapse}
        style={collapsableStyles}
        className={`${styles.sidebar} ${
          collapsed ? styles.collapsed : styles.not_collapsed
        }`}
        width={260}
      >
        <div className={styles.logo}>
          <img
            src={'../static/logo-with-text.png'}
            alt="logo"
            style={{ cursor: 'pointer' }}
            onClick={goToHomePage}
          />
        </div>

        <Divider className={styles.divider} />
        <SideBar collapsed={collapsed} />
      </Sider>
      <Layout style={{ backgroundColor: '#F0F2F5' }}>
        <TopBar toggle={toggle} collapsed={collapsed} />
        <Content style={marginContent()}>
          <ModalDisplayer />
          {children}
        </Content>
        <FooterNav />
      </Layout>
    </Layout>
  );
};

const collapsableStyles = {
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100vh',
  position: 'fixed',
  left: 0,
  zIndex: 99,
  background: '#F0F2F5',
};

export default React.memo(MainPage);
