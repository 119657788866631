/* Network */
export const REQUEST = '[REQUEST]';
export const PENDING = 'API_PENDING';
export const SUCCESS = 'API_SUCCESS';
export const FAILURE = 'API_FAILURE';
/* API */
export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const API_PENDING = 'API_PENDING';
/* CRUD */
export const ADD = 'ADD';
export const CREATE = 'CREATE';
export const READ = 'READ';
export const READ_SINGLE = 'READ_SINGLE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const MASSIVE = 'MASSIVE';
export const DOWNLOAD = 'DOWNLOAD';
/* Count */
export const COUNTER = 'COUNTER';
/* App */
export const SET_RIGHT_DRAWER_VISIBILITY = 'SET_RIGHT_DRAWER_VISIBILITY';
export const SET_USER_SERVICE = 'SET_USER_SERVICE';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const CLEAN_USER_SERVICE = 'CLEAN_USER_SERVICE';
export const TASK = 'TASK';
export const SELECT = 'SELECT';
export const DATE = 'DATE';
export const TIME = '[TIME]';
/* Account */
export const ACCOUNT = '[ACCOUNT]';
export const USER = '[USER]';
export const PLANS = '[PLANS]';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_CODE = 'RECOVER_PASSWORD_CODE';
export const RESET_PASSWORD_ACCOUNT = 'RESET_PASSWORD_ACCOUNT';
export const SAVE_CODE_IN_STORE = 'SAVE_CODE_IN_STORE';
export const CODE_IS_VERIFIED = 'CODE_IS_VERIFIED';
export const REQUEST_ACCOUNTS = `REQUEST_ACCOUNTS`;
export const SYNC = 'SYNC';
/* Care Taker */
export const CARE_TAKER = 'CARE_TAKER';
/* Care Giver */
export const CAREGIVER = 'CAREGIVER';
/* Family Doctor */
export const DOCTOR = 'DOCTOR';
/* Vehicle */
export const VEHICLE = 'VEHICLE';
/* Order */
export const ORDER = 'ORDER';
export const DURATION = 'DURATION';
/* Patient */
export const PATIENT = 'PATIENT';
export const FILTER_PATIENTS_PENDING = 'FILTER_PATIENTS_PENDING';
export const FILTER_PATIENTS_SUCCESS = 'FILTER_PATIENTS_SUCCESS';
export const NATIONALITY = 'NATIONALITY';
export const COUNTRY = 'COUNTRY';
/* Pharmacy */
export const PHARMACY = 'PHARMACY';
/* Insurance */
export const INSURANCE = 'INSURANCE';
/* Health Insurance */
export const INSURANCE_COMPANY = 'INSURANCE_COMPANY';
/* Event */
export const EVENT = 'EVENT';
/* Route */
export const ROUTE = 'ROUTE';
export const DUTY_SCHEDULE = 'DUTY_SCHEDULE';
/* Event */
export const SERVICE = 'SERVICE';
export const SGB05 = 'SGB05';
export const SGB11 = 'SGB11';
/* SignUp */
export const SIGN_UP = '[SIGN_UP]';
/* Modal */
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
/* Filters */
export const STORE_FILTERS = 'STORE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
/* Dashboard */
export const DASHBOARD = '[DASHBOARD]';
export const CHART = '[CHART]';
/* API Configuration */
export const API = '[API]';
/* Labels */
export const LABELS = '[LABELS]';
// PAGINATION
export const RESET_PAGINATION = 'RESET_PAGINATION';
// Invoices
export const INVOICES = 'INVOICES';
export const BILLING = 'BILLING';

// SEARCH
export const SEARCH = '[SEARCH]';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY';
/* EVENTS */
export const SET_DOWNLOADS_RIGHT_DRAWER = 'SET_DOWNLOADS_RIGHT_DRAWER';
export const DOWNLOADS = 'DOWNLOADS';
export const SET_DOWNLOAD = 'SET_DOWNLOAD';
export const REMOVE_FROM_STORE = 'REMOVE_FROM_STORE';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
/* NOTIFICATIONS */
export const SET_SITE_NOTIFICATIONS_RIGHT_DRAWER =
  'SET_SITE_NOTIFICATIONS_RIGHT_DRAWER';
export const SET_SITE_NOTIFICATION = 'SET_SITE_NOTIFICATION';
export const SITE_NOTIFICATIONS = 'SITE_NOTIFICATIONS';
export const REMOVE_SITE_NOTIFICATION_FROM_STORE =
  'REMOVE_SITE_NOTIFICATION_FROM_STORE';
/* Automatizations */
export const AUTOMATIZATIONS = '[AUTOMATIZATIONS]';
export const SET_AUTOMATIZATIONS = 'SET_AUTOMATIZATIONS';
export const SIGNIN = 'SIGNIN';
