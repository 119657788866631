module.exports = {
  locales: ['en', 'de', 'es'],
  defaultLocale: 'en',
  pages: {
    '*': [
      'account',
      'accounting',
      'controlling',
      'calendar',
      'caregiver',
      'caretaker',
      'common',
      'days',
      'delete',
      'doctor',
      'health_insurance',
      'home',
      'itemised_billing',
      'patient',
      'patientContracts',
      'pharmacy',
      'topbar',
      'vehicle',
      'settings',
    ],
  },
};
